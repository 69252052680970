import { Loading } from '@retire/components/Loading'
import { PotName, PotType } from '@retire/constants/pots'
import {
  LATER_LIFE_AGE_PROJECTION_QUERY,
  MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY,
  MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY,
} from '@retire/gql/projections/queries'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useUserDetails } from '@retire/hooks/useUserDetails'
import { isFlexibleIncomePotDataValid, isLaterLifePotDataValid } from '@retire/utils/pots'
import type { FC } from 'react'
import { createContext, useMemo } from 'react'

import { HOLDINGS_QUERY } from '../../gql/queries'
import type { TPotsContext } from './types'

export const PotsContext = createContext<TPotsContext>(null as unknown as TPotsContext)

export const PotsProvider: FC = ({ children }) => {
  const { data: potsHoldings, loading: loadingPotsHoldings } = useQueryWithError(HOLDINGS_QUERY)
  const {
    data: {
      regularIncomeAmount: flexibleIncomePotCustomMonthlyIncomeAmount = 0,
      regularIncomeStartAge: flexibleIncomePotStartAge,
      regularIncomeEndAge: flexibleIncomePotEndAge,
      laterLifeAge: laterLifePotStartAge,
    },
    loading: loadingUserDetails,
  } = useUserDetails()

  const flexibleIncomePotAmount = potsHoldings?.holdings?.flexibleIncomeAmount || 0
  const laterLifePotAmount = potsHoldings?.holdings?.laterLifeAmount || 0
  const inheritancePotAmount = potsHoldings?.holdings?.inheritanceAmount || 0
  const rainyDayPotAmount = potsHoldings?.holdings?.rainyDayAmount || 0
  const totalPotsAmount = flexibleIncomePotAmount + laterLifePotAmount + inheritancePotAmount + rainyDayPotAmount

  const skipFlexibleIncomePotProjection = !isFlexibleIncomePotDataValid({
    startAge: flexibleIncomePotStartAge,
    endAge: flexibleIncomePotEndAge,
    potAmount: flexibleIncomePotAmount,
  })
  const { data: flexibleIncomePotProjectionData } = useQueryWithError(MONTHLY_FLEXIBLE_INCOME_AMOUNT_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount,
      flexibleIncomePotStartAge,
      flexibleIncomePotEndAge,
    },
    skip: skipFlexibleIncomePotProjection,
  })

  const skipLaterLifePotProjection = !isLaterLifePotDataValid({
    startAge: laterLifePotStartAge,
    potAmount: laterLifePotAmount,
  })
  const { data: laterLifePotProjectionData } = useQueryWithError(MONTHLY_LATER_LIFE_AMOUNT_PROJECTION_QUERY, {
    variables: {
      laterLifePotAmount,
      laterLifePotStartAge,
    },
    skip: skipLaterLifePotProjection,
  })

  const skipLaterLifeAgeProjection = skipFlexibleIncomePotProjection || !flexibleIncomePotCustomMonthlyIncomeAmount
  const { data: laterLifeAgeProjectionData } = useQueryWithError(LATER_LIFE_AGE_PROJECTION_QUERY, {
    variables: {
      flexibleIncomePotAmount,
      flexibleIncomePotCustomMonthlyAmount: flexibleIncomePotCustomMonthlyIncomeAmount as number,
      flexibleIncomePotStartAge,
      flexibleIncomePotEndAge,
    },
    skip: skipLaterLifeAgeProjection,
  })

  const loadingPotsContext = useMemo(
    () => loadingPotsHoldings || loadingUserDetails,
    [loadingPotsHoldings, loadingUserDetails]
  )

  const potsContext = useMemo(() => {
    if (loadingPotsContext) {
      return null
    } else {
      return {
        totalPotsAmount,
        pots: {
          [PotName.flexibleIncome]: {
            allocatedAmount: flexibleIncomePotAmount,
            customMonthlyIncome: flexibleIncomePotCustomMonthlyIncomeAmount || 0,
            isLockedForBuys: potsHoldings?.potLocks?.flexibleIncome.lockedForBuys || false,
            isLockedForSells: potsHoldings?.potLocks?.flexibleIncome.lockedForSells || false,
            potName: PotName.flexibleIncome,
            potType: PotType.income,
            projectedMonthlyIncome:
              flexibleIncomePotProjectionData?.projections?.monthlyFlexibleIncomeAmount || undefined,
            surplusAmount: laterLifeAgeProjectionData?.projections?.laterLifeAge?.surplusAmount,
          },
          [PotName.laterLife]: {
            allocatedAmount: laterLifePotAmount,
            isLockedForBuys: potsHoldings?.potLocks?.laterLife.lockedForBuys || false,
            isLockedForSells: potsHoldings?.potLocks?.laterLife.lockedForSells || false,
            potName: PotName.laterLife,
            potType: PotType.income,
            projectedMonthlyIncome: laterLifePotProjectionData?.projections?.monthlyLaterLifeAmount,
          },
          [PotName.inheritance]: {
            allocatedAmount: inheritancePotAmount,
            isLockedForBuys: potsHoldings?.potLocks?.inheritance.lockedForBuys || false,
            isLockedForSells: potsHoldings?.potLocks?.inheritance.lockedForSells || false,
            potName: PotName.inheritance,
            potType: PotType.savings,
          },
          [PotName.rainyDay]: {
            allocatedAmount: rainyDayPotAmount,
            isLockedForBuys: potsHoldings?.potLocks?.rainyDay.lockedForBuys || false,
            isLockedForSells: potsHoldings?.potLocks?.rainyDay.lockedForSells || false,
            potName: PotName.rainyDay,
            potType: PotType.savings,
          },
        },
      }
    }
  }, [
    flexibleIncomePotAmount,
    flexibleIncomePotCustomMonthlyIncomeAmount,
    flexibleIncomePotProjectionData,
    inheritancePotAmount,
    laterLifeAgeProjectionData,
    laterLifePotAmount,
    laterLifePotProjectionData,
    loadingPotsContext,
    potsHoldings,
    rainyDayPotAmount,
    totalPotsAmount,
  ])

  if (loadingPotsContext || !potsContext) {
    return <Loading />
  }

  return <PotsContext.Provider value={potsContext}>{children}</PotsContext.Provider>
}
